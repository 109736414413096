import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogItems from "../components/Blog/Blog"

const Blog = ({ data }) => {
  const allContentfulBlogPost = data.allContentfulBlogPost.edges

  return (
    <Layout blog={true}>
      <Seo title="FaceFile: One way video interviews to screen candidates faster" />
      <BlogItems allContentfulBlogPost={allContentfulBlogPost} />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allContentfulBlogPost {
      edges {
        node {
          blogTitle
          slug
          tag
          postPreviewShortText
          createdAt(formatString: "MMMM DD, YYYY")
          postThumbnailImage {
            gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`
