import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Text } from '@chakra-ui/react'

const Blog = ({ allContentfulBlogPost }) => {
  return (
    <>
      <Box
        maxW={{
          base: '2xl',
          md: '8xl'
        }}
        mx='auto'
        px={{
          base: '6',
          md: '0'
        }}
        display='flex'
        justifyContent='space-between'
        marginTop={20}
      >
        {allContentfulBlogPost.map(({ node }) => (
          <Link
            to={`/blog/${node.slug}`}
            style={{ display: 'block !important', width: '45%' }}
            key={node.slug}
          >
            <Box width='100%'>
              <GatsbyImage
                image={getImage(node.postThumbnailImage.gatsbyImageData)}
                alt={node.slug}
              />
              <Text
                color='#3182CE'
                border='1px solid #3182CE'
                width='174px'
                display='flex'
                justifyContent='center'
                marginTop='20px'
              >
                {node.tag}
              </Text>
              <Text fontWeight={700} fontSize='20px' marginTop='20px'>
                {node.blogTitle}
              </Text>
              <Text
                color='#718096'
                fontWeight='400'
                fontSize='14px'
                marginTop='20px'
              >
                Last Updated {node.createdAt}
              </Text>
              <Text
                color='#4A5568'
                fontWeight='400'
                fontSize='18px'
                marginTop='20px'
              >
                {node.postPreviewShortText}
              </Text>
            </Box>
          </Link>
        ))}
      </Box>
    </>
  )
}
export default Blog
