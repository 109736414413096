/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import { theme } from '@chakra-ui/pro-theme'
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react'
import Header from './header'
import './layout.css'
import { Footer } from './Footer'

const Layout = ({ children, blog = false }) => {
  const myTheme = extendTheme(
    {
      colors: { ...theme.colors, brand: theme.colors.blue }
    },
    theme
  )

  return (
    <ChakraProvider theme={myTheme}>
      <Box h='fit-content'>
        <Header isBlog={blog} />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </Box>
    </ChakraProvider>
  )
}

export default Layout
